import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { App, Button, Card, Col, Form, Input, Modal, Row, Select, Space, Spin, Table, Tooltip } from 'antd';
import {
    AuditOutlined,
    CalculatorOutlined,
    CaretRightOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    EditOutlined,
    EnterOutlined,
    FileExcelTwoTone,
    FileExclamationTwoTone,
    FileImageTwoTone,
    FilePdfTwoTone,
    FilePptTwoTone,
    FileWordTwoTone,
    FileZipTwoTone,
    FormOutlined,
    ForwardOutlined,
    InfoCircleOutlined,
    PaperClipOutlined,
    PlusOutlined,
    RollbackOutlined,
    SaveOutlined,
    SearchOutlined,
    SolutionOutlined,
    StopOutlined,
    ToTopOutlined,
    UndoOutlined,
    UploadOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Resizable } from 'react-resizable';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from '../configs/ThemeConfig';
import axios from 'axios';

/**************************************
 * Title : 공통 전역변수 설정
 **************************************/

/***************************************
 * Title  : 반응형 화면 Size BreakPoint
 * Param  : 반응형으로 작동할 width 값
 * Return : boolean
 ***************************************/
export function useBreakpoint(width) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth >= width);

    useEffect(() => {
        const screenResize = () => {
            setScreenWidth(window.innerWidth >= width);
        };
        window.addEventListener('resize', screenResize);
        return () => {
            window.removeEventListener('resize', screenResize);
        };
    }, []);

    return screenWidth;
}

/*******************************************
 * Title : 반응형 화면 범위 Size BreakPoint
 * Param : 반응형으로 작동할 width 범위 값
 *******************************************/
export function useRangeBreakpoint(width1, width2) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth >= width1 && window.innerWidth <= width2);

    useEffect(() => {
        const screenResize = () => {
            setScreenWidth(window.innerWidth >= width1 && window.innerWidth <= width2);
        };
        window.addEventListener('resize', screenResize);
        return () => {
            window.removeEventListener('resize', screenResize);
        };
    }, []);

    return screenWidth;
}

/**************************************************
 * Title : 현재날짜 가져오기
 **************************************************/
export function gfnNowDate(months = 0) {
    const now = new Date();
    now.setMonth(now.getMonth() + months);
    const year = now.getFullYear().toString();
    const month = now.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    const date = now.getDate();
    const calcData = date.toString().length === 1 ? '0' + date.toString() : date.toString();
    return year + '-' + calcMonth + '-' + calcData;
}

/**************************************************
 * Title : 현재날짜 가져오기(- 없음)
 **************************************************/
export function gfnNowDateNonDash(months = 0) {
    const now = new Date();
    now.setMonth(now.getMonth() + months);
    const year = now.getFullYear().toString();
    const month = now.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    const date = now.getDate();
    const calcData = date.toString().length === 1 ? '0' + date.toString() : date.toString();
    return year + calcMonth + calcData;
}

/**************************************************
 * Title : 현재년월 가져오기
 **************************************************/
export function gfnNowYM() {
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = now.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    return year + '-' + calcMonth;
}

/**************************************************
 * Title : 이전년월 가져오기
 **************************************************/
export function gfnPastMonth() {
    const now = new Date();
    let temp = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const month = temp.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    return temp.getFullYear().toString() + '-' + calcMonth;
}

/**************************************************
 * Title : 월말에 해당하는 최근년월 가져오기 (월초일 경우 전월 반환)
 **************************************************/
export function gfnLateMonth() {
    const now = new Date();
    const day = now.getDate();

    let temp;
    if (day < 10) {
        temp = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    } else {
        temp = new Date(now.getFullYear(), now.getMonth(), 1);
    }
    const month = temp.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    return temp.getFullYear().toString() + '-' + calcMonth;
}

/**************************************************
 * Title : 현재년월 가져오기 - Y년 M월 리턴
 **************************************************/
export function gfnNowYM_Ko() {
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = now.getMonth() + 1;
    const calcMonth = month.toString().length === 1 ? '0' + month.toString() : month.toString();
    return year + '년 ' + calcMonth + '월';
}

/**************************************************
 * Title : 현재년도 가져오기
 **************************************************/
export function gfnNowYear() {
    const now = new Date();
    return now.getFullYear().toString();
}

/**************************************************
 * Title : String 타입 데이터를 Date 형식으로 변환
 * Param : String Type Data
 **************************************************/
export function gfnStrToDate(str) {
    if (str) {
        str = str.toString();
        if (str.length === 14) {
            const year = str.substring(0, 4);
            const month = str.substring(4, 6);
            const day = str.substring(6, 8);
            const hour = str.substring(8, 10);
            const minute = str.substring(10, 12);
            const second = str.substring(12, 14);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        } else if (str.length === 8) {
            const year = str.substring(0, 4);
            const month = str.substring(4, 6);
            const day = str.substring(6, 8);
            return `${year}-${month}-${day}`;
        } else {
            return str;
        }
    } else {
        return null;
    }
}

/**************************************************
 * Title : String YYYYMMDD 형식의 데이터가 날짜 형식이 맞는지 체크하여 true, false 반환
 * Param : String Type Data
 **************************************************/
export function isValidDate(dateString) {
    // 숫자로만 이루어진 8자리 문자열인지 확인
    if (!/^\d{8}$/.test(dateString)) {
        return false;
    }

    // 년, 월, 일 추출
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    // Date 객체 생성
    const date = new Date(year, month - 1, day);

    // 날짜 객체의 년, 월, 일이 입력한 값과 동일한지 확인
    return (
        date.getFullYear() === parseInt(year, 10) &&
        date.getMonth() + 1 === parseInt(month, 10) &&
        date.getDate() === parseInt(day, 10)
    );
}

/*********************************************************
 * Title : String 타입 YYYYMM 데이터의 한달전 YYYYMM으로 변환
 * Param : String Type Data
 *********************************************************/
export function gfnOneMonthAgo(str) {
    if (str) {
        str = str.toString();
        if (str.length === 14) {
            // yyyyMMddHHmmss
            return null;
        } else if (str.length === 8) {
            // yyyyMMdd
            return null;
        } else if (str.length === 6) {
            // 입력된 날짜 문자열을 Date 객체로 변환
            const currentDate = new Date(str.substring(0, 4), str.substring(4, 6) - 1, 1);

            // 한 달 전 날짜 계산
            currentDate.setMonth(currentDate.getMonth() - 1);

            // 날짜를 yyyyMM 형식으로 변환 후 Return
            // ko-KR로 지정할 경우 월, 일 1자리일 때 앞에 0을 붙이기 어려움
            const year = currentDate
                .toLocaleDateString('en-US', {
                    year: 'numeric',
                })
                .replace('.', ''); // 마침표 제거

            const month = currentDate
                .toLocaleDateString('en-US', {
                    month: '2-digit',
                })
                .replace('.', ''); // 마침표 제거

            return year + month;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

/********************************************************
 * Title : 좌측 메뉴 내 서브메뉴 키 값 배열로 생성
 * Param : 탭 메뉴 데이터
 ********************************************************/
export function gfnCreateSubmenukeys(data) {
    const arrMenu = [];
    const arrKey = [];
    for (const element of data) {
        if (element !== null && element !== undefined && element.children !== null && element.children !== undefined) {
            arrMenu.push(element);
        }
    }

    const fData = arrMenu.filter(d => d.children !== data.children);
    fData.forEach(e => {
        arrKey.push(e.key);
    });

    return arrKey;
}

/**********************************************************
 * Title : 탭 변경 또는 탭 닫기 실행 시 좌측 메뉴 열고 닫기
 * Param : 탭 메뉴 데이터, 탭 key 정보, 화면 openKeys 상태값
 **********************************************************/
export function gfnMenuOpenClose(menuItems, key, setOpenKeys) {
    const arrMenu = [];
    const arrKey = [];
    for (const element of menuItems) {
        if (element !== null && element !== undefined && element.children !== null && element.children !== undefined) {
            arrMenu.push(element);
        }
    }
    const index = key.lastIndexOf('/') + 1;
    const strKey = key.substring(index);
    const fData = arrMenu.filter(d => d.children !== arrMenu.children);

    for (const element of fData) {
        for (let n = 0; n < element.children.length; n++) {
            if (element.children[n].key === strKey) {
                arrKey.push(element);
                break;
            }
        }
    }

    if (arrKey.length > 0) {
        setOpenKeys([arrKey[0].key]);
    } else {
        setOpenKeys([strKey]);
    }
}

/********************************************************
 * Title : 각 화면에 정의한 버튼과 공통 버튼 맵핑 공통함수
 * Param : 공통버튼 배열, 화면에서 정의한 버튼 배열
 ********************************************************/
export function gfnBtnActMapper(a = [], b = []) {
    return b.map(v => {
        const d = a.find(e => e['apiEndPoint'] === v['url']);
        return d !== undefined ? { ...v, ...d } : null;
    });
}

/**
 * 버튼 loading, disabled 상태 처리함수
 *
 * @param btnInfo 버튼 속성 선언용 json array
 * @param btnUrl 상태처리 대상 button url
 * @param flag 상태값(boolean)
 */
export const gfnBtnLoading = (btnInfo, btnUrl, flag) => {
    return new Promise((resolve, reject) => {
        try {
            btnInfo.forEach(item => {
                if (item.url === btnUrl) {
                    if (item.loading !== undefined && Array.isArray(item.loading) && item.loading.length === 2)
                        item.loading[1](flag);
                } else {
                    if (item.disabled !== undefined && Array.isArray(item.disabled) && item.disabled.length === 2)
                        item.disabled[1](flag);
                }
            });
            window.setTimeout(() => {
                resolve();
            }, 0);
        } catch (e) {
            reject(e);
        }
    });
};

/*****************************************
 * Title : 화면마다 사용할 공통버튼 생성
 * Param : 화면 width 값, 맵핑된 버튼 정보
 *****************************************/
export const OznetCommButton = ({
    viewWidth = 992,
    gfnBtnMapping,
    group = null,
    align = 'end',
    sizeChangeYn = true,
    display = 'block',
    disabledYn = 'N',
    btnSize = 'small',
    marginLeft = 0,
}) => {
    const screenWidth = useBreakpoint(viewWidth);
    const currentTheme = useSelector(state => state.theme.currentTheme);
    const themeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme };
    const icon = {
        none: null,
        SearchOutlined: <SearchOutlined />, // 조회
        EditOutlined: <EditOutlined />, //입력
        SaveOutlined: <SaveOutlined />, // 수정
        PlusOutlined: <PlusOutlined />, // 추가
        CloseOutlined: <CloseOutlined />, // 삭제, 닫기
        CheckOutlined: <CheckOutlined />, // 확인
        FormOutlined: <FormOutlined />, // 재작성
        AuditOutlined: <AuditOutlined />, // 접수
        SolutionOutlined: <SolutionOutlined />, // 담당자 지정
        UsergroupAddOutlined: <UsergroupAddOutlined />, // 결재
        ToTopOutlined: <ToTopOutlined />, // 결재상신
        RollbackOutlined: <RollbackOutlined />, // 상신취소
        EnterOutlined: <EnterOutlined />, // 반려, 반송
        PaperClipOutlined: <PaperClipOutlined />, // 첨부파일
        UploadOutlined: <UploadOutlined />, // 엑셀 업로드
        DownloadOutlined: <DownloadOutlined />, // 엑셀 다운로드
        UndoOutlined: <UndoOutlined />, // 초기화
        CaretRightOutlined: <CaretRightOutlined />, // 실행
        StopOutlined: <StopOutlined />, // 중지
        ForwardOutlined: <ForwardOutlined />, // 재실행
        CalculatorOutlined: <CalculatorOutlined />, // 계산
    };
    return (
        <div style={{ display: display, textAlign: align, marginLeft: marginLeft }}>
            <Space block={'true'} wrap={true} align={'end'} size={[4, 0]} direction={'horizontal'}>
                {gfnBtnMapping.length > 0 ? (
                    gfnBtnMapping.map((item, index) => {
                        if (item != null) {
                            const tooltipLoc = gfnBtnMapping.length === index + 1;
                            if (item.group === group) {
                                const loadingFlag =
                                    item.loading !== undefined &&
                                    Array.isArray(item.loading) &&
                                    item.loading.length === 2;
                                const disabledFlag =
                                    disabledYn === 'N'
                                        ? item.disabled !== undefined &&
                                          Array.isArray(item.disabled) &&
                                          item.disabled.length === 2
                                        : item.disabled;

                                // 버튼 style 지정
                                const fnStyle = () => {
                                    if (disabledYn === 'N') {
                                        if (disabledFlag && item.disabled[0] === true) {
                                            return themeConfig.components.Button.btnStyles.disabled;
                                        } else {
                                            return themeConfig.components.Button.btnStyles[item.type];
                                        }
                                    } else {
                                        if (item.disabled) {
                                            return themeConfig.components.Button.btnStyles.disabled;
                                        } else {
                                            return themeConfig.components.Button.btnStyles[item.type];
                                        }
                                    }
                                };

                                // 버튼 disabled 지정
                                const fnDisabled = () => {
                                    if (disabledYn === 'N') {
                                        if (disabledFlag) {
                                            return item.disabled[0];
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return item.disabled;
                                    }
                                };

                                return (
                                    <Form.Item key={item.url}>
                                        <Tooltip
                                            placement={tooltipLoc ? 'topRight' : 'top'}
                                            arrow={false}
                                            title={sizeChangeYn && !screenWidth ? item.title : ''}
                                        >
                                            <Button
                                                key={item.url}
                                                icon={icon[item.icon]}
                                                onClick={() => (item.event ? item.event(item.url) : null)}
                                                block={true}
                                                size={btnSize}
                                                style={fnStyle()}
                                                disabled={fnDisabled()}
                                                loading={loadingFlag ? item.loading[0] : false}
                                            >
                                                {sizeChangeYn && !screenWidth ? (
                                                    ''
                                                ) : (
                                                    <span
                                                        style={
                                                            btnSize === 'middle'
                                                                ? {
                                                                      fontSize: '16px',
                                                                      marginBottom: '1px',
                                                                  }
                                                                : btnSize === 'large'
                                                                ? { fontSize: '20px' }
                                                                : {}
                                                        }
                                                    >
                                                        {item.title}
                                                    </span>
                                                )}
                                            </Button>
                                        </Tooltip>
                                    </Form.Item>
                                );
                            }
                        }
                    })
                ) : (
                    <div style={{ height: '34px' }} />
                )}
            </Space>
        </div>
    );
};

/*****************************************
 * Title : 화면마다 사용할 공통 Spin 컴포넌트
 * Param : spinning -> boolean
 *****************************************/
export function OznetSpin({ children, spinning, ...props }) {
    return (
        <Spin className={'oznet-comm-spin'} spinning={spinning} {...props}>
            {children}
        </Spin>
    );
}

/***************************************************
 * Title : 테이블 Search Filter
 * Param : Search Filter 적용할 해당 컬럼 dataIndex
 ***************************************************/
export function useFilter(dataIndex) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={e => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`검색할 내용을 입력하세요`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        조회
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        초기화
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        필터
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        닫기
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    };
}

/********************************************
 * Title : 테이블 내 Row Selection
 * rowkey : 선택한 Row의 row-key 값
 * e : 이벤트 발생 값
 * dataRowkey, setDataRowkey : rowkey 상태값
 * element, setElement : element 상태값
 ********************************************/
export function gfnRowSelection(rowkey, e, dataRowkey, setDataRowkey, element, setElement) {
    const targetElement = e.target.parentElement;
    if (dataRowkey === null) {
        for (const item of targetElement.children) {
            item.style.background = '#ffe7ba';
        }
        setDataRowkey(rowkey);
        setElement(targetElement);
    } else if (dataRowkey === rowkey) {
        for (const item of targetElement.children) {
            item.style.background = null;
        }
        setDataRowkey(null);
        setElement(null);
    } else if (dataRowkey !== rowkey) {
        for (const item of element.children) {
            item.style.background = null;
        }

        for (const item of targetElement.children) {
            item.style.background = '#ffe7ba';
        }
        setDataRowkey(rowkey);
        setElement(targetElement);
    }
}

export function gfnRowClick(e, index) {
    if (e !== null) {
        e.target.parentElement.parentElement.querySelectorAll('tr.ant-table-row').forEach((item, i) => {
            item.style.cssText = i === index ? `font-weight: bold; text-decoration: underline;` : '';
        });
        e.target.parentElement.parentElement.parentElement.querySelectorAll('tr.ant-table-row').forEach((item, i) => {
            item.style.cssText = i === index ? `font-weight: bold; text-decoration: underline;` : '';
        });
    }
}

export function gfnRowClickReset(className) {
    document.querySelectorAll(`div .${className}`).forEach(item => {
        item.style = `background-color: "";`;
    });
}

/**************************************
 * 테이블 Column Resize 컴포넌트 생성
 **************************************/
// Resize 함수 및 그리드 component
const ResizableTitle = props => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

/**************************************
 * 테이블 Drag & Drop Row 컴포넌트 생성
 **************************************/
const TableRow = props => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        ),
        transition,
        cursor: 'move',
        ...(isDragging
            ? {
                  position: 'relative',
                  zIndex: 9999,
              }
            : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

/****************************************
 * 공통 테이블 컴포넌트
 * tableColumns : 테이블 헤더 컬럼
 * data         : 테이블 내부 데이터
 * layout       : fixed, auto
 * widthLayout  : 테이블 가로 Layout 설정
 * yScrollYn    : y축 scroll 사용여부
 * ySize        : y축 scroll 사이즈
 * dragYn       : Row Drag&Drop 사용여부
 * props        : 각종 이벤트 등
 ****************************************/
export const OznetTable = forwardRef(
    (
        {
            tableColumns,
            data,
            layout = 'fixed',
            yScrollYn = true,
            //ySize = 0,
            dragYn = false,
            colResizeYn = 'Y',
            footerYn = false,
            ...props
        },
        ref,
    ) => {
        // 변수 및 state 선언
        const [tableData, setTableData] = useState(data);

        // ** useImperativeHandle setup
        useImperativeHandle(ref, () => ({
            getDataAll: tableData,
        }));

        // set data
        useEffect(() => {
            setTableData(data);
        }, [data]);

        // 그리드 컬럼 세팅
        const [columns, setColumns] = useState(tableColumns);

        useEffect(() => {
            setColumns(tableColumns);
        }, [tableColumns]);

        const indexRef = useRef(null);
        const startXRef = useRef(null);
        const startWidthRef = useRef(null);

        // 컬럼 Resize
        const handleResize = useCallback((column, index) => {
            return (e, { size }) => {
                e.preventDefault();
                const { clientX } = e;

                let deltaX;
                let newSize;

                if (startXRef.current === null) {
                    indexRef.current = index;
                    startXRef.current = clientX;
                    startWidthRef.current = size.width;
                    return;
                } else if (indexRef.current === null || indexRef.current !== index) {
                    indexRef.current = index;
                    startXRef.current = clientX;
                    startWidthRef.current = size.width;
                    return;
                } else {
                    deltaX = clientX - startXRef.current;
                    newSize = startWidthRef.current + deltaX;
                }

                if (newSize < 20) return;

                setColumns(prevColumns => {
                    const updateColumnWidth = (columns, dataIndex, newSize) => {
                        return columns.map(col => {
                            if (col.children) {
                                return { ...col, children: updateColumnWidth(col.children, dataIndex, newSize) };
                            }
                            if (col.dataIndex === dataIndex) {
                                return { ...col, width: newSize };
                            }
                            return col;
                        });
                    };
                    startXRef.current = clientX;
                    startWidthRef.current = newSize;
                    return updateColumnWidth(prevColumns, column.dataIndex, newSize);
                });
            };
        }, []);

        // Resize된 컬럼
        const resizeColumn = columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: column.fixed ? null : handleResize(column, index),
            }),
        }));

        // DndContext sensors 정의
        const sensors = useSensors(
            useSensor(PointerSensor, {
                activationConstraint: {
                    distance: 1,
                },
            }),
        );

        // DndContext dragEnd 이벤트 정의
        const onDragEnd = ({ active, over }) => {
            if (active.id !== over?.id) {
                setTableData(prev => {
                    const activeIndex = prev.findIndex(i => i.key === active.id);
                    const overIndex = prev.findIndex(i => i.key === over?.id);
                    return arrayMove(prev, activeIndex, overIndex);
                });
            }
        };

        // 테이블 Y축 스크롤 세팅
        /*
        const yScroll = () => {
            return yScrollYn
                ? `calc(100vh - ${TEMPLATE.HEADER_HEIGHT}px - ${TEMPLATE.TOP_NAV_HEIGHT}px - ${TEMPLATE.CONTENT_HEIGHT_OFFSET}px - (${TEMPLATE.LAYOUT_CONTENT_GUTTER}px * 2) + ${TEMPLATE.FOOTER_HEIGHT}px + ${ySize}px)`
                : '100%';
        };
        */
        // footer 정의
        const footer = () => {
            return <span style={{ fontWeight: 'bold' }}>Total Count : {gfnComma(data.length)} 건</span>;
        };

        OznetTable.displayName = 'OznetTable';

        return (
            <>
                {yScrollYn ? (
                    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext items={data.map(i => i.key)} strategy={verticalListSortingStrategy}>
                            <Table
                                tableLayout={layout}
                                bordered
                                components={{
                                    header:
                                        colResizeYn === 'Y'
                                            ? {
                                                  cell: ResizableTitle,
                                              }
                                            : {},
                                    body:
                                        colResizeYn === 'Y'
                                            ? {
                                                  row: dragYn ? TableRow : null,
                                              }
                                            : {},
                                }}
                                columns={colResizeYn === 'Y' ? resizeColumn : columns}
                                dataSource={tableData}
                                scroll={{
                                    y: '100%',
                                }}
                                size={'small'}
                                pagination={{
                                    position: ['none', 'bottomLeft'],
                                    defaultPageSize: 50,
                                    showTotal: total => `Total Count : ${gfnComma(total)} 건`,
                                }}
                                footer={footerYn === true ? footer : false}
                                {...props}
                            />
                        </SortableContext>
                    </DndContext>
                ) : (
                    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext items={data.map(i => i.key)} strategy={verticalListSortingStrategy}>
                            <Table
                                tableLayout={layout}
                                bordered
                                components={{
                                    header:
                                        colResizeYn === 'Y'
                                            ? {
                                                  cell: ResizableTitle,
                                              }
                                            : {},
                                    body:
                                        colResizeYn === 'Y'
                                            ? {
                                                  row: dragYn ? TableRow : null,
                                              }
                                            : {},
                                }}
                                columns={colResizeYn === 'Y' ? resizeColumn : columns}
                                dataSource={tableData}
                                size={'small'}
                                pagination={{
                                    position: ['none', 'bottomLeft'],
                                    defaultPageSize: 50,
                                    showTotal: total => `Total Count : ${gfnComma(total)} 건`,
                                }}
                                footer={footerYn === true ? footer : false}
                                {...props}
                            />
                        </SortableContext>
                    </DndContext>
                )}
            </>
        );
    },
);

/***************************************
 * Title : 테이블 내 헤더 Object
 * Param : Object 내용, 정렬값
 ***************************************/
export const OznetTableTitle = ({ title, align = 'center' }) => {
    return <div style={{ textAlign: align }}>{title}</div>;
};

/*****************************************
 * Title : 공통 Success
 * message : alert 메세지 내용
 *****************************************/
export const gfnSuccessModal = (message, fnOk = null, btnView = true) => {
    Modal.destroyAll();
    return {
        className: 'oznet-comm-gfnSuccessModal',
        title: '성공',
        content: message,
        centered: true,
        okButtonProps: btnView
            ? {
                  icon: <CheckOutlined />,
                  className: 'btn-comm-success-ok',
                  type: 'default',
              }
            : {
                  style: { display: 'none' },
              },
        okText: '확인',
        onOk: fnOk,
    };
};

/******************************************
 * Title : 일정시간 이후 사라지는 공통 Success
 * message : alert 메세지 내용
 ******************************************/
export const gfnSuccess = (modal, message, fnOk = null, time = 1000, close = true) => {
    if (close) {
        Modal.destroyAll();
    }
    const instance = modal.success(gfnSuccessModal(message, fnOk, false));
    setTimeout(() => {
        instance.destroy();
    }, time);
};

/*****************************************
 * Title : 공통 Alert
 * message : alert 메세지 내용
 *****************************************/
export const gfnAlert = message => {
    Modal.destroyAll();
    return {
        className: 'oznet-comm-gfnAlert',
        title: '시스템 메세지',
        content: message,
        centered: true,
        okButtonProps: {
            icon: <CheckOutlined />,
            className: 'btn-comm-alert-ok',
            style: { background: '#389e0d', color: 'white', height: '28px', padding: '3px 7px 4px 7px' },
            type: 'default',
        },
        okText: '확인',
    };
};

/*****************************************
 * Title : 공통 Confirm
 * message : Confirm 메세지 내용
 * props : onOk, onCancel event
 *****************************************/
export const gfnConfirm = (title, message, fnOk) => {
    Modal.destroyAll();
    return {
        className: 'oznet-comm-gfnConfirm',
        title: title,
        icon: <InfoCircleOutlined />,
        content: message,
        centered: true,
        okButtonProps: {
            icon: <CheckOutlined />,
            className: 'btn-comm-confirm-ok',
            size: 'small',
            style: { background: '#389e0d', color: 'white', height: '28px', padding: '3px 7px 4px 7px' },
        },
        okText: '확인',
        cancelButtonProps: {
            icon: <CloseOutlined />,
            className: 'btn-comm-confirm-cancel',
            size: 'small',
            style: { height: '28px', padding: '3px 7px 4px 7px' },
        },
        cancelText: '취소',
        onOk: fnOk,
    };
};

/*****************************************
 * Title : 공통팝업 - 부서원
 * prgPath : 화면 경로
 * isPopOpen : 팝업 오픈여부 상태값
 * setIsPopOpen : 팝업 오픈여부 상태값
 * setUserInfo : 선택한 유저정보 Return 값
 * deptCd : 부서코드
 *****************************************/
export const OznetUserInfoPopup = ({ apiList, prgPath, isPopOpen, setIsPopOpen, setUserInfo, deptCd = null }) => {
    // Oznet 공통함수 - 화면별 반응형 사이즈 설정
    const screenWidth = useBreakpoint(576);

    // 변수, state 세팅
    const { modal } = App.useApp();
    const [commUserSearchForm] = Form.useForm();
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState(null);

    // 테이블 헤더 필터 세팅
    const filterData = data =>
        data.map(item => ({
            key: item,
            value: item,
            text: item,
        }));

    // 테이블 컬럼 세팅
    const columns = [
        {
            title: '사번',
            dataIndex: 'userId',
            key: 'userId',
            width: 100,
            align: 'center',
            ellipsis: true,
            filters: filterData(
                searchData.map(item => item['userId']).filter((value, index, self) => self.indexOf(value) === index),
            ),
            filterSearch: true,
            onFilter: (value, record) => record['userId'].includes(value),
            sorter: (a, b) => a['userId'].localeCompare(b['userId']),
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            align: 'center',
            ellipsis: true,
            editable: true,
            filters: filterData(
                searchData.map(item => item['name']).filter((value, index, self) => self.indexOf(value) === index),
            ),
            filterSearch: true,
            onFilter: (value, record) => record['name'].includes(value),
            sorter: (a, b) => a['name'].localeCompare(b['name']),
        },
        {
            title: '직위',
            dataIndex: 'posNm',
            key: 'posNm',
            width: 100,
            align: 'center',
            ellipsis: true,
            editable: true,
            filters: filterData(
                searchData.map(item => item['posNm']).filter((value, index, self) => self.indexOf(value) === index),
            ),
            filterSearch: true,
            onFilter: (value, record) => record['posNm'].includes(value),
            sorter: (a, b) => a['posNm'].localeCompare(b['posNm']),
        },
    ];

    // 조회
    const fnSearch = btnUrl => {
        // 권한 체크
        if (gfnBtnMapping[0] === null) {
            modal.error(gfnAlert('권한이 유효하지 않습니다.'));
            return false;
        } else {
            btnUrl = gfnBtnMapping[0]['url'];
        }

        // 유효성 체크
        commUserSearchForm
            .validateFields()
            .then(() => {
                // 조회 트랜잭션
                fnTranSearch(btnUrl);
            })
            .catch(() => {});
    };

    // 조회 트랜잭션
    const fnTranSearch = btnUrl => {
        try {
            gfnBtnLoading(btnInfo, btnUrl, true);
            setLoading(true);
            setSearchData([]);
            const getFieldsValue = commUserSearchForm.getFieldsValue();
            const data = { ...getFieldsValue };
            data['deptCd'] = deptCd;

            axios
                .post(btnUrl, data)
                .then(response => {
                    setSearchData(response.data);
                })
                .catch(error => {
                    modal.error(gfnAlert(error.message));
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                    gfnBtnLoading(btnInfo, btnUrl, false);
                });
        } catch (e) {
            modal.error(gfnAlert(e.message));
            return false;
        }
    };

    // 선택버튼 이벤트
    const fnOk = () => {
        if (!rowData) {
            modal.error(gfnAlert('사용자를 선택해 주세요.'));
        } else {
            setUserInfo([rowData]);
            fnClose();
        }
    };

    // 닫기버튼 이벤트
    const fnClose = () => {
        setRowData(null);
        setSearchData([]);
        setIsPopOpen(false);
    };

    // 버튼 맵핑
    const btnInfo = [
        {
            url: `/api${prgPath}/select-cosm-user-list`,
            title: '조회',
            icon: 'SearchOutlined',
            type: 'warning',
            event: fnSearch,
            loading: useState(false),
            disabled: useState(false),
            group: null,
        },
    ];

    // Oznet 공통함수 - 버튼별 Action 맵핑
    const gfnBtnMapping = gfnBtnActMapper(apiList, btnInfo);

    // 화면 진입시 컴포넌트 초기화
    useEffect(() => {
        if (isPopOpen) {
            commUserSearchForm.resetFields();
            setSearchData([]);
            if (deptCd !== null) {
                fnSearch(gfnBtnMapping[0]['url']);
            }
        }
    }, [isPopOpen]);

    return (
        <Modal
            title={deptCd ? '부서원 검색' : '직원 검색'}
            centered
            open={isPopOpen}
            onOk={fnOk}
            onCancel={fnClose}
            maskClosable={false}
            footer={null}
        >
            <Form form={commUserSearchForm}>
                <Card className="card-search-sm" style={{ margin: '0 0 10px 0', padding: '4px 0 4px 0' }}>
                    <Row gutter={[8, 0]} justify={'start'} style={screenWidth ? { flexWrap: 'nowrap' } : {}}>
                        {/*구분 Select Box 시작*/}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                            <Form.Item
                                name="commDivFlag"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[
                                    {
                                        required: deptCd ? false : true,
                                        message: '조회 구분은 필수 선택입니다.',
                                    },
                                ]}
                                initialValue={'name'}
                            >
                                <Select
                                    placeholder="선택"
                                    options={[
                                        {
                                            value: 'name',
                                            label: '이름',
                                        },
                                        {
                                            value: 'userId',
                                            label: '사번',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        {/*구분 Select Box 끝*/}

                        {/*검색어 입력 시작*/}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={10}>
                            <Form.Item
                                name="commSearchValue"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[
                                    {
                                        required: deptCd ? false : true,
                                        message: '검색어는 필수 입력입니다.',
                                    },
                                    {
                                        max: 20,
                                        message: '검색어는 20자 이하로 입력하여야 합니다.',
                                    },
                                ]}
                                initialValue={''}
                            >
                                <Input placeholder="검색어" onPressEnter={fnSearch} maxLength={20} autoComplete="off" />
                            </Form.Item>
                        </Col>
                        {/*검색어 입력 끝*/}

                        {/*조회버튼 입력 시작*/}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                            <OznetCommButton gfnBtnMapping={gfnBtnMapping} align={'left'} />
                        </Col>
                        {/*조회버튼 입력 끝*/}
                    </Row>
                </Card>
            </Form>
            <div className={'oznet-display'} style={{ height: '350px' }}>
                <OznetTable
                    tableColumns={columns}
                    data={searchData}
                    rowKey={row => row['userId']}
                    loading={loading}
                    onRow={(record, index) => {
                        return {
                            onClick: e => {
                                gfnRowClick(e, index);
                                setRowData(record);
                            },
                            onDoubleClick: () => {
                                setUserInfo([record]);
                                fnClose();
                            },
                        };
                    }}
                />
            </div>
            {screenWidth ? (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Button
                        icon={<CheckOutlined />}
                        key={'btn_comm_emp_ok'}
                        onClick={fnOk}
                        style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                    >
                        선택
                    </Button>
                    <Button
                        icon={<CloseOutlined />}
                        key={'btn_comm_emp_close'}
                        onClick={fnClose}
                        style={{ marginLeft: '4px' }}
                    >
                        닫기
                    </Button>
                </div>
            ) : (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip placement={'top'} arrow={false} title={'선택'}>
                        <Button
                            icon={<CheckOutlined />}
                            key={'btn_comm_emp_ok'}
                            onClick={fnOk}
                            style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                        />
                    </Tooltip>
                    <Tooltip placement={'top'} arrow={false} title={'닫기'}>
                        <Button
                            icon={<CloseOutlined />}
                            key={'btn_comm_emp_close'}
                            onClick={fnClose}
                            style={{ marginLeft: '4px' }}
                        />
                    </Tooltip>
                </div>
            )}
        </Modal>
    );
};

/*****************************************
 * Title : 공통팝업 - 업체 (등록업체)
 * isPopOpen : 팝업 오픈여부 상태값
 * setIsPopOpen : 팝업 오픈여부 상태값
 * setCompanyInfo : 선택한 업체정보 Return 값
 *****************************************/
export const OznetCompanyInfoPopup = ({ apiList, prgPath, isPopOpen, setIsPopOpen, setCompanyInfo }) => {
    // Oznet 공통함수 - 화면별 반응형 사이즈 설정
    const screenWidth = useBreakpoint(576);

    // 변수, state 세팅
    const { modal } = App.useApp();
    const [commCompanySearchForm] = Form.useForm();
    const [rowData, setRowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState([]);

    // 테이블 헤더 필터 세팅
    const filterData = data =>
        data.map(item => ({
            value: item,
            text: item,
        }));

    // 테이블 컬럼 세팅
    const columns = [
        {
            title: '업체코드',
            dataIndex: 'vendCd',
            width: 90,
            align: 'center',
            ellipsis: true,
            sorter: (a, b) => a['vendCd'].localeCompare(b['vendCd']),
        },
        {
            title: <OznetTableTitle title={'업체명'} />,
            dataIndex: 'vendName',
            width: 180,
            align: 'left',
            ellipsis: true,
            editable: true,
            filters: filterData(
                resultData.map(item => item['vendName']).filter((value, index, self) => self.indexOf(value) === index),
            ),
            filterSearch: true,
            onFilter: (value, record) => record['vendName'].includes(value),
            sorter: (a, b) => a['vendName'].localeCompare(b['vendName']),
        },
        {
            title: '사업자 등록번호',
            dataIndex: 'taxpayrNo',
            width: 100,
            align: 'center',
            ellipsis: true,
            editable: true,
            render: text => {
                return <span>{text?.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')}</span>;
            },
        },
    ];

    // 조회
    const fnSearch = btnUrl => {
        // 권한 체크
        if (gfnBtnMapping[0] === null) {
            modal.error(gfnAlert('권한이 유효하지 않습니다.'));
            return false;
        } else {
            btnUrl = gfnBtnMapping[0]['url'];
        }

        // 유효성 체크
        commCompanySearchForm
            .validateFields()
            .then(() => {
                // 조회 트랜잭션
                fnTranSearch(btnUrl);
            })
            .catch(() => {});
    };

    // 조회 트랜잭션
    const fnTranSearch = btnUrl => {
        try {
            gfnBtnLoading(btnInfo, btnUrl, true);
            setLoading(true);
            setResultData([]);

            axios
                .post(btnUrl, commCompanySearchForm.getFieldsValue())
                .then(response => {
                    setResultData(response.data);
                })
                .catch(error => {
                    modal.error(gfnAlert(error.message));
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                    gfnBtnLoading(btnInfo, btnUrl, false);
                });
        } catch (e) {
            modal.error(gfnAlert(e.message));
            return false;
        }
    };

    // 선택버튼 이벤트
    const fnOk = () => {
        if (!rowData) {
            modal.error(gfnAlert('업체를 선택해 주세요.'));
        } else {
            setCompanyInfo([rowData]);
            fnClose();
        }
    };

    // 닫기버튼 이벤트
    const fnClose = () => {
        setRowData(null);
        setResultData([]);
        setIsPopOpen(false);
    };

    // 버튼 맵핑
    const btnInfo = [
        {
            url: `/api${prgPath}/select-vendor-list`,
            title: '조회',
            icon: 'SearchOutlined',
            type: 'warning',
            event: fnSearch,
            loading: useState(false),
            disabled: useState(false),
            group: null,
        },
    ];

    // Oznet 공통함수 - 버튼별 Action 맵핑
    const gfnBtnMapping = gfnBtnActMapper(apiList, btnInfo);

    // 화면 진입시 실행
    useEffect(() => {
        if (isPopOpen) {
            commCompanySearchForm.resetFields();
            setResultData([]);
            fnSearch(gfnBtnMapping.length > 0 ? gfnBtnMapping[0]['url'] : null);
        }
    }, [isPopOpen]);

    return (
        <Modal
            title={'업체 검색'}
            centered
            width={550}
            open={isPopOpen}
            onCancel={fnClose}
            maskClosable={false}
            footer={null}
        >
            <Form form={commCompanySearchForm}>
                <Card className="card-search-sm" style={{ margin: '0 0 10px 0', padding: '4px 0 4px 0' }}>
                    <Row gutter={[8, 0]} justify={'start'} style={screenWidth ? { flexWrap: 'nowrap' } : {}}>
                        {/*검색구분 시작*/}
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Form.Item
                                name="searchValue"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[
                                    {
                                        required: true,
                                        message: '구분은 필수선택입니다.',
                                    },
                                    {
                                        max: 20,
                                        message: '검색어는 20자 이하로 입력하여야 합니다.',
                                    },
                                ]}
                                initialValue={'vendName'}
                            >
                                <Select
                                    options={[
                                        {
                                            label: '업체명',
                                            value: 'vendName',
                                        },
                                        {
                                            label: '업체코드',
                                            value: 'vendCd',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        {/*검색구분 끝*/}

                        {/*검색어 입력 시작*/}
                        <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                            <Form.Item
                                name="searchWord"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                            >
                                <Input placeholder="검색어" onPressEnter={fnSearch} autoComplete="off" maxLength={20} />
                            </Form.Item>
                        </Col>
                        {/*검색어 입력 끝*/}

                        {/*조회버튼 입력 시작*/}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                            <OznetCommButton gfnBtnMapping={gfnBtnMapping} align={'left'} />
                        </Col>
                        {/*조회버튼 입력 끝*/}
                    </Row>
                </Card>
            </Form>
            <div className={'oznet-display'} style={{ height: '350px' }}>
                <OznetTable
                    tableColumns={columns}
                    data={resultData}
                    rowKey={row => row['vendCd']}
                    loading={loading}
                    onRow={(record, index) => {
                        return {
                            onClick: e => {
                                gfnRowClick(e, index);
                                setRowData(record);
                            },
                            onDoubleClick: () => {
                                setCompanyInfo([record]);
                                fnClose();
                            },
                        };
                    }}
                />
            </div>
            {screenWidth ? (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Button
                        icon={<CheckOutlined />}
                        key={'btn_comm_vend_ok'}
                        onClick={fnOk}
                        style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                    >
                        선택
                    </Button>
                    <Button
                        icon={<CloseOutlined />}
                        key={'btn_comm_vend_close'}
                        onClick={fnClose}
                        style={{ marginLeft: '4px' }}
                    >
                        닫기
                    </Button>
                </div>
            ) : (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip placement={'top'} arrow={false} title={'선택'}>
                        <Button
                            icon={<CheckOutlined />}
                            key={'btn_comm_vend_ok'}
                            onClick={fnOk}
                            style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                        />
                    </Tooltip>
                    <Tooltip placement={'top'} arrow={false} title={'닫기'}>
                        <Button
                            icon={<CloseOutlined />}
                            key={'btn_comm_vend_close'}
                            onClick={fnClose}
                            style={{ marginLeft: '4px' }}
                        />
                    </Tooltip>
                </div>
            )}
        </Modal>
    );
};

/************************************************
 * Title : 공통팝업 - 인터페이스 운영부서(코스트센터)
 * isPopOpen : 팝업 오픈여부 상태값
 * setIsPopOpen : 팝업 오픈여부 상태값
 * setUserInfo : 선택한 부서정보 Return 값
 * corpCd : 회사 코드
 * pbzCd : 사업장 코드
 ************************************************/
export const OznetIfCostCenterPopup = ({ apiList, prgPath, isPopOpen, setIsPopOpen, setCostInfo, corpCd, pbzCd }) => {
    // Oznet 공통함수 - 화면별 반응형 사이즈 설정
    const screenWidth = useBreakpoint(576);

    // 변수, state 세팅
    const { modal } = App.useApp();
    const [commCostSearchForm] = Form.useForm();
    const [rowData, setRowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState([]);

    // 테이블 헤더 필터 세팅
    const filterData = data => {
        return data.map(item => ({
            value: item,
            text: item,
        }));
    };

    // 테이블 컬럼 세팅
    const columns = [
        {
            title: '운영부서',
            dataIndex: 'kostl',
            width: 100,
            align: 'center',
            ellipsis: true,
            sorter: (a, b) => a['kostl'].localeCompare(b['kostl']),
        },
        {
            title: <OznetTableTitle title={'운영부서명'} />,
            dataIndex: 'kltxt',
            width: 200,
            align: 'left',
            ellipsis: true,
            editable: true,
            sorter: (a, b) => a['kltxt'].localeCompare(b['kltxt']),
        },
    ];

    // 조회
    const fnSearch = btnUrl => {
        // 권한 체크
        if (gfnBtnMapping[0] === null) {
            modal.error(gfnAlert('권한이 유효하지 않습니다.'));
            return false;
        } else {
            btnUrl = gfnBtnMapping[0] && gfnBtnMapping[0]['url'];
        }

        // 유효성 체크
        commCostSearchForm
            .validateFields()
            .then(() => {
                // 조회 트랜잭션
                fnTranSearch(btnUrl);
            })
            .catch(() => {});
    };

    // 조회 트랜잭션
    const fnTranSearch = btnUrl => {
        try {
            gfnBtnLoading(btnInfo, btnUrl, true);
            setLoading(true);
            setResultData([]);
            const data = { ...commCostSearchForm.getFieldsValue(), corpCd: corpCd, pbzCd: pbzCd };

            axios
                .post(btnUrl, { ...data })
                .then(response => {
                    setResultData(response.data);
                })
                .catch(error => {
                    modal.error(gfnAlert(error.message));
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                    gfnBtnLoading(btnInfo, btnUrl, false);
                });
        } catch (e) {
            modal.error(gfnAlert(e.message));
            return false;
        }
    };

    // 선택버튼 이벤트
    const fnOk = () => {
        if (!rowData) {
            modal.error(gfnAlert('운영부서를 선택해 주세요.'));
        } else {
            setCostInfo([rowData]);
            fnClose();
        }
    };

    // 닫기버튼 이벤트
    const fnClose = () => {
        setRowData(null);
        setIsPopOpen(false);
    };

    // 버튼 맵핑
    const btnInfo = [
        {
            url: `/api${prgPath}/select-co-ccent-list`,
            title: '조회',
            icon: 'SearchOutlined',
            type: 'warning',
            event: fnSearch,
            loading: useState(false),
            disabled: useState(false),
            group: null,
        },
    ];

    // Oznet 공통함수 - 버튼별 Action 맵핑
    const gfnBtnMapping = gfnBtnActMapper(apiList, btnInfo);

    // 화면 진입시 실행
    useEffect(() => {
        if (isPopOpen) {
            commCostSearchForm.resetFields();
            setResultData([]);
        }
    }, [isPopOpen]);

    return (
        <Modal
            title={'운영부서 검색'}
            centered
            width={500}
            open={isPopOpen}
            onOk={fnOk}
            onCancel={fnClose}
            maskClosable={false}
            footer={null}
        >
            <Form form={commCostSearchForm}>
                <Card className="card-search-sm" style={{ margin: '0 0 10px 0', padding: '4px 0 4px 0' }}>
                    <Row gutter={[8, 0]} justify={'start'} style={screenWidth ? { flexWrap: 'nowrap' } : {}}>
                        {/*검색어 입력 시작*/}
                        <Col xxl={14} xl={14} lg={14} md={14} sm={14} xs={16}>
                            <Form.Item
                                name="searchWord"
                                label="검색어"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[
                                    {
                                        required: true,
                                        message: '검색어는 필수입력입니다.',
                                    },
                                    {
                                        max: 20,
                                        message: '검색어는 20자 이하로 입력하여야 합니다.',
                                    },
                                ]}
                            >
                                <Input placeholder="검색어" onPressEnter={fnSearch} maxLength={20} autoComplete="off" />
                            </Form.Item>
                        </Col>
                        {/*검색어 입력 끝*/}

                        {/*조회버튼 입력 시작*/}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                            <OznetCommButton gfnBtnMapping={gfnBtnMapping} align={'left'} />
                        </Col>
                        {/*조회버튼 입력 끝*/}
                    </Row>
                </Card>
            </Form>
            <div className={'oznet-display'} style={{ height: '350px' }}>
                <OznetTable
                    tableColumns={columns}
                    data={resultData}
                    rowKey={row => row['kostl']}
                    loading={loading}
                    onRow={(record, index) => {
                        return {
                            onClick: e => {
                                gfnRowClick(e, index);
                                setRowData(record);
                            },
                            onDoubleClick: () => {
                                setCostInfo([record]);
                                fnClose();
                            },
                        };
                    }}
                    footerYn={true}
                    pagination={false}
                />
            </div>
            {screenWidth ? (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Button
                        icon={<CheckOutlined />}
                        key={'btn_comm_co_ccent_ok'}
                        onClick={fnOk}
                        style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                    >
                        선택
                    </Button>
                    <Button
                        icon={<CloseOutlined />}
                        key={'btn_comm_co_ccent_close'}
                        onClick={fnClose}
                        style={{ marginLeft: '4px' }}
                    >
                        닫기
                    </Button>
                </div>
            ) : (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip placement={'top'} arrow={false} title={'선택'}>
                        <Button
                            icon={<CheckOutlined />}
                            key={'btn_comm_co_ccent_ok'}
                            onClick={fnOk}
                            style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                        />
                    </Tooltip>
                    <Tooltip placement={'top'} arrow={false} title={'닫기'}>
                        <Button
                            icon={<CloseOutlined />}
                            key={'btn_comm_co_ccent_close'}
                            onClick={fnClose}
                            style={{ marginLeft: '4px' }}
                        />
                    </Tooltip>
                </div>
            )}
        </Modal>
    );
};

/*****************************************
 * Title : 공통팝업 - 인터페이스 사용자
 * isPopOpen : 팝업 오픈여부 상태값
 * setIsPopOpen : 팝업 오픈여부 상태값
 * setCompanyInfo : 선택한 사용자정보 Return 값
 *****************************************/
export const OznetIfUserInfoPopup = ({ apiList, prgPath, isPopOpen, setIsPopOpen, setIfUserInfo }) => {
    // Oznet 공통함수 - 화면별 반응형 사이즈 설정
    const screenWidth = useBreakpoint(576);

    // 변수, state 세팅
    const { modal } = App.useApp();
    const [commIfUserSearchForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [resultData, setResultData] = useState([]);

    // 화면 진입시 컴포넌트 초기화
    useEffect(() => {
        if (isPopOpen) {
            commIfUserSearchForm.resetFields();
            setResultData([]);
            setRowData(null);
        }
    }, [isPopOpen]);

    // 테이블 헤더 필터 세팅
    const filterData = data =>
        data.map(item => ({
            value: item,
            text: item,
        }));

    // 테이블 컬럼 세팅
    const columns = [
        {
            title: '사번',
            dataIndex: 'userId',
            width: 85,
            align: 'center',
            ellipsis: true,
            sorter: (a, b) => a['userId'].localeCompare(b['userId']),
        },
        {
            title: '성명',
            dataIndex: 'name',
            width: 90,
            align: 'center',
            ellipsis: true,
            editable: true,
            sorter: (a, b) => a['name'].localeCompare(b['name']),
        },
        {
            title: '직위',
            dataIndex: 'posNm',
            width: 95,
            align: 'center',
            ellipsis: true,
            editable: true,
            sorter: (a, b) => a['posNm'].localeCompare(b['posNm']),
        },
        {
            title: <OznetTableTitle title={'부서명'} />,
            dataIndex: 'deptNm',
            width: 150,
            align: 'left',
            ellipsis: true,
            editable: true,
            sorter: (a, b) => a['deptNm'].localeCompare(b['deptNm']),
        },
    ];

    // 조회
    const fnSearch = btnUrl => {
        // 권한 체크
        if (gfnBtnMapping[0] === null) {
            modal.error(gfnAlert('권한이 유효하지 않습니다.'));
            return false;
        } else {
            btnUrl = gfnBtnMapping[0] && gfnBtnMapping[0]['url'];
        }

        // 유효성 체크
        commIfUserSearchForm
            .validateFields()
            .then(() => {
                // 조회 트랜잭션
                fnTranSearch(btnUrl);
            })
            .catch(() => {});
    };

    // 조회 트랜잭션
    const fnTranSearch = btnUrl => {
        try {
            gfnBtnLoading(btnInfo, btnUrl, true);
            setLoading(true);
            setResultData([]);

            axios
                .post(btnUrl, commIfUserSearchForm.getFieldsValue())
                .then(response => {
                    setResultData(response.data);
                })
                .catch(error => {
                    modal.error(gfnAlert(error.message));
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                    gfnBtnLoading(btnInfo, btnUrl, false);
                });
        } catch (e) {
            modal.error(gfnAlert(e.message));
            return false;
        }
    };

    // 선택버튼 이벤트
    const handleOk = () => {
        if (!rowData) {
            modal.error(gfnAlert('사용자를 선택해 주세요.'));
        } else {
            setIfUserInfo([rowData]);
            handleCancel();
        }
    };

    // 닫기버튼 이벤트
    const handleCancel = () => {
        setResultData([]);
        setRowData(null);
        setIsPopOpen(false);
    };

    // 버튼 맵핑
    const btnInfo = [
        {
            url: `/api${prgPath}/select-if-user-list`,
            title: '조회',
            icon: 'SearchOutlined',
            type: 'warning',
            event: fnSearch,
            loading: useState(false),
            disabled: useState(false),
            group: null,
        },
    ];

    // Oznet 공통함수 - 버튼별 Action 맵핑
    const gfnBtnMapping = gfnBtnActMapper(apiList, btnInfo);

    return (
        <Modal
            title={'사용자 검색'}
            centered
            width={650}
            open={isPopOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form form={commIfUserSearchForm}>
                <Card className="card-search-sm" style={{ margin: '0 0 10px 0', padding: '4px 0 4px 0' }}>
                    <Row gutter={[8, 0]} justify={'start'} style={screenWidth ? { flexWrap: 'nowrap' } : {}}>
                        {/*검색구분 시작*/}
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Form.Item
                                name="searchValue"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[{ required: true, message: '구분은 필수선택입니다.' }]}
                                initialValue={'name'}
                            >
                                <Select
                                    options={[
                                        {
                                            label: '성명',
                                            value: 'name',
                                        },
                                        {
                                            label: '사번',
                                            value: 'userId',
                                        },
                                        {
                                            label: '부서명',
                                            value: 'deptNm',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        {/*검색구분 끝*/}

                        {/*검색어 입력 시작*/}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Form.Item
                                name="searchWord"
                                labelCol={{ flex: '0 0 auto' }}
                                wrapperCol={screenWidth ? {} : { flex: '1 1 auto' }}
                                rules={[
                                    {
                                        required: true,
                                        message: '검색어는 필수 입력입니다.',
                                    },
                                    {
                                        min: 2,
                                        message: '검색어는 적어도 ${min}글자 이상이어야 합니다.',
                                    },
                                    {
                                        max: 20,
                                        message: '검색어는 20자 이하로 입력하여야 합니다.',
                                    },
                                ]}
                            >
                                <Input placeholder="검색어" onPressEnter={fnSearch} maxLength={20} autoComplete="off" />
                            </Form.Item>
                        </Col>
                        {/*검색어 입력 끝*/}

                        {/*조회버튼 입력 시작*/}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                            <OznetCommButton gfnBtnMapping={gfnBtnMapping} align={'left'} />
                        </Col>
                        {/*조회버튼 입력 끝*/}
                    </Row>
                </Card>
            </Form>
            <div className={'oznet-display'} style={{ height: '350px' }}>
                <OznetTable
                    tableColumns={columns}
                    data={resultData}
                    rowKey={row => row['userId']}
                    loading={loading}
                    onRow={(record, index) => {
                        return {
                            onClick: e => {
                                gfnRowClick(e, index);
                                setRowData(record);
                            },
                            onDoubleClick: () => {
                                setIfUserInfo([record]);
                                handleCancel();
                            },
                        };
                    }}
                />
            </div>
            {screenWidth ? (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Button
                        icon={<CheckOutlined />}
                        key={'btn_comm_if_user_ok'}
                        onClick={handleOk}
                        style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                    >
                        선택
                    </Button>
                    <Button
                        icon={<CloseOutlined />}
                        key={'btn_comm_if_user_close'}
                        onClick={handleCancel}
                        style={{ marginLeft: '4px' }}
                    >
                        닫기
                    </Button>
                </div>
            ) : (
                <div style={{ display: 'flex', marginTop: 8, justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip placement={'top'} arrow={false} title={'선택'}>
                        <Button
                            icon={<CheckOutlined />}
                            key={'btn_comm_if_user_ok'}
                            onClick={handleOk}
                            style={{ background: '#389e0d', color: 'white', paddingBottom: '1px' }}
                        />
                    </Tooltip>
                    <Tooltip placement={'top'} arrow={false} title={'닫기'}>
                        <Button
                            icon={<CloseOutlined />}
                            key={'btn_comm_if_user_close'}
                            onClick={handleCancel}
                            style={{ marginLeft: '4px' }}
                        />
                    </Tooltip>
                </div>
            )}
        </Modal>
    );
};

/***********************************************
 * Title : Search sorter 함수
 * a, b : a[ColumnName], b[ColumnName]
 * 사용예:
 *    sorter: (a, b) => {
 *        return gfnValLocaleCompare(a["userId"], b["userId"])
 *    }
 ***********************************************/
export const gfnValLocaleCompare = (a, b) => {
    if (a && b) {
        return a.toString().localeCompare(b.toString());
    } else if (a) {
        return -1;
    } else if (b) {
        return 1;
    }
    return 0;
};

/*****************************************
 * Title : 투자예산번호 패턴 처리
 * Param : 투자예산번호
 *****************************************/
export const gfnBudgetNoReg = value => {
    return value === null ? null : value.toString().replace(/^(\D{1,2})(\d{6,8})(\d{4})$/, `$1.$2-$3`);
};

/***************************************************
 * Title : 입력한 투자예산번호 특수문자 삭제 패턴 처리
 * Param : 특수문자 포함 된 투자예산번호
 ***************************************************/
export const gfnBudgetNoDelReg = value => {
    let reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/ ]/gim;
    return value === null ? null : value.toString().replace(reg, '');
};

/*****************************************
 * Title : 사내용역비계정 패턴 처리
 * Param : 사내용역비계정
 *****************************************/
export const gfnAccountCdReg = value => {
    return value === null ? null : value.toString().replace(/^(\d{3})(\d{2})(\d{3})$/, `$1-$2-$3`);
};

/***************************************************
 * Title : 입력한 사내용역비계정 계정코드 삭제 패턴 처리
 * Param : 특수문자 포함 된 투자예산번호
 ***************************************************/
export const gfnAccountCdDelReg = value => {
    let reg = /[A-Zㄱ-ㅎㅏ-ㅣ가-힣`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/ ]/gim;
    return value === null ? null : value.toString().replace(reg, '');
};

/***************************************************
 * Title : 입력한 문자 중 숫자만 남기도록 패턴 처리
 * Param : 각종 문자열
 ***************************************************/
export const gfnStrToNum = value => {
    return value === null ? null : value.toString().replace(/[^\d.-]|(?<=\d)-/g, '');
};

/**************************************************
 * Title : 천단위 콤마 생성 (조회 시 사용)
 * Param : 변환할 값
 **************************************************/
export function gfnComma(value) {
    if (value === null || value === undefined) return null;
    const conValue = value.toString().replace(/[^-\d.-]/g, '');
    const numValue = Number(conValue);
    if (isNaN(numValue)) return value;
    const strNumValue = numValue.toString();
    const parts = strNumValue.split('.');

    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**************************************************
 * Title : 천단위 콤마 + 소수점 생성 (조회 시 사용)
 * Param : 변환할 값
 **************************************************/
export function gfnCommaDot(value) {
    if (value === null || value === undefined) return null;
    const conValue = value.toString().replace(/[^-\d.-]/g, '');
    const parts = conValue.split('.');

    if (parts.length === 1 || parts[1] === null || parts[1] === undefined) {
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (Number(parts[1]) === 0) {
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        const numValue = Number(conValue);
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + numValue.toString().split('.')[1];
    }
}

/***************************************************
 * Title : 3자리마다 콤마 추가 패턴 처리 (입력 시 사용)
 * Param : 각종 문자열
 ***************************************************/
export const gfnAddComma = value => {
    if (value === null || value === undefined) return null;
    const conValue = value.toString().replace(/[^-\d.-]/g, '');
    const numValue = Number(conValue);
    if (isNaN(numValue)) return value;
    const strNumValue = numValue.toString();
    const parts = strNumValue.split('.');

    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/***************************************************
 * Title : 3자리마다 콤마 추가 패턴 처리, 소수점.은 유지 (입력 시 사용)
 * Param : 각종 문자열
 ***************************************************/
export const gfnAddCommaDot = value => {
    if (value === null || value === undefined) return null;
    const conValue = value.toString().replace(/[^-\d.-]/g, '');
    const parts = conValue.split('.');

    if (parts[1] === null || parts[1] === undefined) {
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + parts[1];
    }
};

/***************************************************
 * Title : 콤마 제거 패턴 처리
 * Param : 각종 문자열
 ***************************************************/
export const gfnDelComma = value => {
    if (value === null || value === undefined) return null;
    const conValue = value.toString().replace(/[^-\d.-]/g, '');
    const parts = conValue.split('.');

    if (parts[1] === null || parts[1] === undefined || parts[1] === '' || Number(parts[1]) === 0) {
        return parts[0];
    } else {
        const numValue = Number(conValue);
        return parts[0] + '.' + numValue.toString().split('.')[1];
    }
};

/***************************************************
 * Title : 파일 업로드시 확장자 체크
 * Param : 업로드할 파일
 ***************************************************/
export const gfnChkFileExt = file => {
    // 확장자 블랙리스트 선언
    const blackListExt = [
        '.php',
        '.php3',
        '.php4',
        '.php5',
        '.phtml', // PHP 파일
        '.js', // JavaScript 파일
        '.html',
        '.htm', // HTML 파일
        '.java', // Java 소스 코드 파일
        '.exe',
        '.bat',
        '.sh',
        '.jar', // 실행 가능한 파일
        '.app',
        '.dmg',
        '.pkg', // macOS 실행 파일
        '.com',
        '.cmd', // Windows 실행 파일
        '.vbs',
        '.wsf', // 스크립트 파일
        '.asp',
        '.aspx',
        '.ascx', // ASP 파일
        '.jsp',
        '.jspx', // JSP 파일
        '.dll', // DLL 파일
        '.class', // Java 클래스 파일
    ];

    // 첨부파일 체크
    const fileName = file.name;
    const fileExt = '.' + fileName.split('.').pop().toLowerCase();
    if (blackListExt.indexOf(fileExt) !== -1) {
        // 허용되지 않는 확장자가 있는 경우
        return false;
    } else {
        // 모든 파일이 허용된 확장자일 경우
        return true;
    }
};

/***************************************************
 * Title : 파일 확장자에 따른 Icon Component 반환
 * file : 업로드할 파일
 * size : 아이콘 사이즈
 ***************************************************/
export const gfnFileIcon = (file, size) => {
    const fileExt = file.name.split('.').pop().toLowerCase();
    if (fileExt === 'xls' || fileExt === 'xlsx') return <FileExcelTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'jpg' || fileExt === 'jpeg') return <FileImageTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'ppt' || fileExt === 'pptx') return <FilePptTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'doc' || fileExt === 'docx') return <FileWordTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'pdf') return <FilePdfTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'gif') return <FileImageTwoTone style={{ fontSize: size }} />;
    if (fileExt === 'zip') return <FileZipTwoTone style={{ fontSize: size }} />;
    return <FileExclamationTwoTone style={{ fontSize: size }} />;
};

/***************************************************
 * Title : RangePicker Cell 커스텀 렌터링 (숫자만 표기)
 * Param : current 렌더링 대상 날짜
 * Param : current RangePicker 속성정보
 ***************************************************/
export function rangePickerCellRender(current, info) {
    if (info.type !== 'date') {
        return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
        return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return <div className="ant-picker-cell-inner">{current.date()}</div>;
}
